<template>
  <v-dialog eager v-model="dialog" width="1200px" persistent>
    <BaseEditCardEntity
      :entity="currentEntity"
      :tab-items="editCardItems"
      entity-type="siege"
      :is-edit="isEditForm"
      v-on:close="closeNewSeat"
      :save-item="saveSeat"
      :key="currentEntity.id"
      :is-loading="loadingSiege"
      no-border-top
      no-elevation
      :active-tab.sync="activeTab"
      :disabled-save="loadingCheckMember || loadingAdvertisers || loadingSiegeRules || hasSiegeRuleErrors"
    >
      <template v-slot:CRM="{ entity }">
        <v-row wrap v-if="entity">
          <v-col cols="6" class="pr-1">
            <v-row no-gutters wrap class="form-block-keystoneV2 h-auto elevation-2">
              <v-col cols="12">
                <v-text-field
                  label="Name"
                  v-model.trim="entity.siege.name"
                  required
                  :error-messages="errorMessageName(entity.siege.name)"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  label="ID"
                  :value="entity.siege.id"
                  disabled
                >
                </v-text-field>
              </v-col>

              <v-col cols="12">
                <ChildEntityAutocomplete
                  v-model="entity.siege.company"
                  :return-value-if-null="() => {}"
                  :entitySearchType="'company'"
                  :error-messages="errorMessageValueCantBeEmpty(entity.siege?.company?.id, 'company')"
                ></ChildEntityAutocomplete>
              </v-col>

              <v-col cols="12">
                <ChildEntityAutocomplete
                  v-model="entity.siege.company.holding"
                  :entitySearchType="'holding'"
                  :disabled="true"
                  ref="holding-auto"
                  :error-messages="errorMessageValueCantBeEmpty(entity.siege?.company?.holding?.id, 'holding')"
                ></ChildEntityAutocomplete>
              </v-col>

              <v-col cols="12">
                <v-autocomplete
                  label="Country"
                  v-model="entity.siege.company.country"
                  disabled
                  :items="$store.getters.getCountries"
                  item-text="name"
                  item-value="id"
                  return-object
                  :loading="$store.getters.getLoadingCountries"
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12">
                <v-select
                  label="Sales in charge"
                  :items="saleItems"
                  multiple
                  v-model="selectedSales"
                  :loading="loadingCollaborators"
                  item-text="item.name"
                >
                  <template v-slot:item="{ item }">
                    {{ getCollaboratorNameFromMail(item.dv_mail != null ? item.dv_mail : item.email) }}
                  </template>
                  <template v-slot:selection="{ item }">
                    {{ `${getCollaboratorNameFromMail(item.dv_mail != null ? item.dv_mail : item.email)},` }}
                  </template>
                </v-select>
              </v-col>

              <v-col cols="12">
                <v-select
                  label="Am in charge"
                  :items="amItems"
                  multiple
                  v-model="selectedAms"
                  :loading="loadingCollaborators"
                >
                  <template v-slot:item="{ item }">
                    {{ getCollaboratorNameFromMail(item.dv_mail != null ? item.dv_mail : item.email) }}
                  </template>
                  <template v-slot:selection="{ item }">
                    {{ `${getCollaboratorNameFromMail(item.dv_mail != null ? item.dv_mail : item.email)},` }}
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" class="pl-1">
            <v-row no-gutters wrap class="form-block-keystoneV2 h-auto elevation-2">
              <v-col cols="12">
                <strong>Linked DSP member</strong>
              </v-col>

              <v-col cols="12" v-if="hasSiegeRuleErrors && !loadingSiegeRulesChecker && !loadingSiegeRules">
                <v-alert type="error">
                  You can't create a Seat that advertisers in common with another seat.
                  The member {{currentEntity.member.externalId}} is already linked :
                  <br>
                  {{errorSiegeRules[0]}}
                  <br>
                  please change your seat rules
                </v-alert>
              </v-col>

              <v-col cols="12">
                <v-row wrap>
                  <v-col cols="4">
                    <v-select
                      label="DSP"
                      :items="dspItems"
                      v-model="entity.member.dsp"
                      required
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="4" class="text-center">
                    <v-avatar :size="50">
                      <img
                        v-if="entity && entity.member && entity.member.dsp"
                        :src="require('../../../assets/' + entity.member.dsp + '.png')"
                        :alt="entity.member.dsp"
                        :title="entity.member.dsp"
                      />
                    </v-avatar>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="Member external id"
                      v-model.trim="entity.member.externalId"
                      :disabled="!entity || !entity.member.dsp"
                      :loading="loadingCheckMember"
                      :error-messages="errorMessageValueCantBeEmpty(entity.member.id, 'member')"
                    >
                    </v-text-field>
                    <div>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" text :disabled="!checkedMemberExist || loadingCheckMember || !isEditForm" @click="goToMember(entity)">
                            <v-icon small class="pr-2">
                              launch
                            </v-icon>
                            Member config
                          </v-btn>
                        </template>
                        <span>
                          Open member config
                        </span>
                      </v-tooltip>
                    </div>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" v-if="entity && entity.member && !entity.member.isSet && checkedMemberExist">
                <v-alert type="warning" :value="true">
                  Member configuration has to be completed by the tech team
                </v-alert>
              </v-col>

              <v-col cols="12" v-if="entity.member && entity.member.externalId && !checkedMemberExist && !loadingCheckMember">
                <v-row wrap>
                  <v-col cols="6">
                    <v-alert type="warning" border="top">
                      Warning member does not exist yet in our DB, do you want to create it? (make sure it's a valid id)
                    </v-alert>
                  </v-col>
                  <v-col cols="6">
                    <v-btn
                      @click="createNewMemberNotSet"
                      text
                      :disabled="!entity.member || !entity.member.externalId || !entity.member.dsp"
                      :loading="processNewMemberNotSet"
                    >
                      Create new member in our DB
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" v-if="loadingSiegeRules" class="text-center">
                <v-progress-circular indeterminate color="primary">

                </v-progress-circular>
              </v-col>
              <v-col cols="12" v-if="!loadingSiegeRules">
                <v-alert type="info" :value="noComplexSiegeRulePossible">
                  No complex siege rule possible as Member is not configured yet
                </v-alert>
                <v-radio-group
                  v-model="specificOrAllAd"
                  :disabled="!memberIsSet || noComplexSiegeRulePossible || !checkedMemberExist"
                  :error-messages="errorMessageAgencyAdvertiser()"
                >
                  <v-radio label="Full Member" value="fullMember">

                  </v-radio>
                  <v-radio :label="labelSpecificAd" value="specificAd">

                  </v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" v-if="!loadingSiegeRules && specificOrAllAd !== 'fullMember' && memberIsSet">
                <v-row wrap>
                  <v-col cols="12">
                    <v-row wrap>
                      <v-col cols="12" v-if="currentEntityDsp !== $MEDIAMATH">
                        <v-radio-group
                          v-model="allBut"
                          row
                          :value="false"
                        >
                          <template v-slot:label>
                            <div>Select rule : </div>
                          </template>
                          <v-radio
                            label="Only"
                            :value="false"
                            aria-checked="true"
                          ></v-radio>
                          <v-radio
                            label="All but"
                            :value="true"
                          ></v-radio>
                        </v-radio-group>
                        <ChildEntityAutocomplete
                          v-model="selectedAdvertiser"
                          :entitySearchType="'advertiser'"
                          :item-disabled="itemDisabledAdvertiser"
                          @input="addAdvertiser()"
                          :search-parent-id="currentEntity.member.id"
                        >
                        </ChildEntityAutocomplete>
                        <div class="d-flex justify-space-around">
                          <InputFile
                            class="flex-grow-1"
                            :data.sync="advertisersOfMember"
                            :typeFileCheck.sync="importSuccess"
                            :accept-type="typeFile"
                            :buttonValue="importButtonValue"
                            :colorBackground="importButtonColor"
                          ></InputFile>
                          <InputTextList
                            class="flex-grow-1"
                            :data.sync="advertisersOfMember"
                          ></InputTextList>
                        </div>
                      </v-col>
                      <v-col cols="12" v-else>
                        <v-radio-group
                          v-model="allBut"
                          row
                          :value="false"
                        >
                          <template v-slot:label>
                            <div>Select rule : </div>
                          </template>
                          <v-radio
                            label="Only"
                            :value="false"
                            aria-checked="true"
                          ></v-radio>
                          <v-radio
                            label="All but"
                            :value="true"
                          ></v-radio>
                        </v-radio-group>
                        <ChildEntityAutocomplete
                          v-model="selectedAgency"
                          :entitySearchType="'agency'"
                          :item-disabled="itemDisabledAgency"
                          @input="addAgency()"
                          :do-not-add-entity-to-result="true"
                          :search-parent-id="currentEntity.member.id"
                        >
                        </ChildEntityAutocomplete>
                        <div class="d-flex justify-space-around">
                          <InputFile
                            class="flex-grow-1"
                            :data.sync="advertisersOfMember"
                            :accept-type="typeFile"
                            :buttonValue="importButtonValue"
                            :colorBackground="'primary'"
                          ></InputFile>
                          <InputTextList
                            class="flex-grow-1"
                            :data.sync="advertisersOfMember"
                          ></InputTextList>
                        </div>
                      </v-col>
                      <v-col cols="12"  v-if="!loadFullAdvertisers">
                        <v-alert type="warning">
                          One or several advertiser ID could not be imported :
                          {{ listOfNotMatchIds.join(', ') }}
                          <CopyClipboardComponent
                            :to-copy="listOfNotMatchIds.toString()"
                            tooltip-text="Copy list of not match ids"
                            :elevation="false"
                            btn-class="''"
                          >
                          </CopyClipboardComponent>
                        </v-alert>
                      </v-col>
                      <v-col cols="12" v-if="!importSuccess">
                        <v-alert>
                          The file could not be imported !
                        </v-alert>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" class="chipsArea" >
                    <v-row wrap v-if="currentEntityDsp !== $MEDIAMATH">
                      <v-col v-for="(item, key) in advertiserList" :key="key">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-chip v-on="on" close @click:close="removeAdvertiser(item)">
                              {{item.externalId}}
                            </v-chip>
                          </template>
                          <span>
                            {{ item.name }}
                          </span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                    <v-row wrap v-else>
                      <v-col v-for="(item, key) in agencyList" :key="key">
                        <v-chip close @click:close="removeAgency(item)">
                          {{item}}
                        </v-chip>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>
      <template v-slot:PROPERTIES="{ entity }">
        <v-row no-gutters wrap justify-space-between>
          <v-col cols="6">
            <v-row no-gutters column>
              <v-col class="mb-4 pr-1" cols="12">
                <v-row no-gutters wrap class="form-block-keystoneV2 h-auto elevation-2" >
                  <v-col cols="12" class="title-form-keystoneV2">
                    DATA
                  </v-col>
                  <v-col cols="12" v-if="dspHasDspLogin">
                    <DspLoginInfo
                      :dsp-login="dspLogin"
                      :max-length="60"
                      class-label="label-btn"
                      class-value="value-data"
                    >
                    </DspLoginInfo>
                  </v-col>
                  <v-col cols="12">
                    <SettingsIsActiveInfo
                      :dsp-settings="dspSettings"
                      class-label="label-btn"
                      class-value="value-data"
                    >
                    </SettingsIsActiveInfo>
                  </v-col>
                  <v-col>
                    <DspSettingsInfo
                      :dsp-settings="dspSettings"
                      :current-dsp="currentDsp"
                      class-label="label-btn"
                      class-value="value-data"
                    >
                    </DspSettingsInfo>
                  </v-col>

                  <v-col cols="12" v-if="[$APPNEXUS].indexOf(currentDsp) !== -1">
                    <DspSettingsForm
                      :dsp-settings="dspSettings"
                      :current-dsp="currentDsp"
                    >
                    </DspSettingsForm>
                  </v-col>

                  <v-col cols="12" v-if="dspSettings === null && !isLoadingDspSettings">
                    <v-alert type="warning" icon="warning">
                      No dsp settings
                    </v-alert>
                  </v-col>

                  <v-col cols="12" v-if="isLoadingDspSettings">
                    <v-progress-circular
                      :size="30"
                      color="primary"
                      indeterminate
                    ></v-progress-circular>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="mb-4 pr-1">
                <v-row no-gutters wrap class="form-block-keystoneV2 h-auto elevation-2" >
                  <v-col cols="12" class="title-form-keystoneV2">
                    CUSTOM PROD MANAGEMENT
                  </v-col>
                  <v-col cols="12">
                    <CheckBoxWithInfo
                      v-model="entity.prodUnderdeliveryCustom"
                      label="Underdelivery Custom"
                      info-text="Raise sensibility of alerting (daily & intraday) about underdelivery. If not combined with Overdelivery Custom, automatically apply conservative mode on Otto."
                    >
                    </CheckBoxWithInfo>
                  </v-col>
                  <v-col cols="12">
                    <CheckBoxWithInfo
                      v-model="entity.prodInverseOttoWaterfall"
                      label="Inverse Otto Waterfall"
                      info-text="[Only for margin campaigns] Give priority to delivery before margin constraint"
                    >
                    </CheckBoxWithInfo>
                  </v-col>
                  <v-col cols="12">
                    <CheckBoxWithInfo
                      v-model="entity.prodOverdeliveryCustom"
                      label="Overdelivery Custom"
                      info-text="Raise sensibility of alerting (daily & intraday) about overdelivery."
                    >
                    </CheckBoxWithInfo>
                  </v-col>
                  <v-col cols="12">
                    <CheckBoxWithInfo
                      v-model="entity.prodRespectMinimumLiBudget"
                      :label="labelRespectMinimumLiBudget"
                      info-text="Force minimum delivery on each line. This option could damage performance."
                    >
                    </CheckBoxWithInfo>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="mb-4 pr-1" cols="12">
                <v-row no-gutters wrap class="form-block-keystoneV2 h-auto elevation-2" >
                  <v-col cols="12" class="title-form-keystoneV2">
                    FEES {{ currentEntityDsp }}
                  </v-col>
                  <v-col cols="12" v-if="currentEntityDsp === $APPNEXUS">
                    <v-radio-group
                      v-model="entity.prodFeeApplicationMode"
                      :error-messages="errorMessageFeeApplicationMode()"
                    >
                      <v-radio
                        :disabled="!entity.memberSetting.prodHasSegmentFeeAvailable"
                        :label="getFeeApplicationModeLabel(currentEntityDsp, 'segment')"
                        value="segment"
                      >
                      </v-radio>

                      <v-radio
                        :label="getFeeApplicationModeLabel(currentEntityDsp, 'partner_fee')"
                        value="partner_fee"
                      >
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" v-else-if="[$THETRADEDESK, $DBM].includes(currentEntityDsp)">
                    <v-radio-group
                      v-model="entity.prodFeeApplicationMode"
                      :error-messages="errorMessageFeeApplicationMode()"
                    >
                      <v-radio
                        :label="getFeeApplicationModeLabel(currentEntityDsp, 'cleared_by_dsp')"
                        value="cleared_by_dsp"
                      >
                      </v-radio>

                      <v-radio
                        :label="getFeeApplicationModeLabel(currentEntityDsp, 'partner_fee')"
                        value="partner_fee"
                      >
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12">
                    <v-tooltip bottom :disabled="currentEntityDsp !== $MEDIAMATH">
                      <template v-slot:activator="{ on }">
                        <div v-on="on">
                          <CheckBoxWithInfo
                            v-model="entity.prodAddServingFees"
                            label="Automatically push our fees ?"
                            color="success"
                            :disabled="currentEntityDsp === $MEDIAMATH"
                            :readonly="prodAddServingFeesIsReadonly"
                            :info-text="currentEntityDsp === $THETRADEDESK
                              ? 'When active, you should specify our fees\' names as keywords in the markup module at seat or IO level'
                              : null"
                          >
                          </CheckBoxWithInfo>
                        </div>
                      </template>
                      <span>
                        Automatically pushing fee are not available on mediamath
                      </span>
                    </v-tooltip>
                    <v-select
                      v-if="entity.prodFeeApplicationMode === 'segment' && currentEntityDsp === $APPNEXUS"
                      v-model.trim="entity.prodFeeValue"
                      :items="feeValueAppnexusSegment"
                      label="Fee Value"
                      suffix="%"
                      :error-messages="errorMessageFeeValue()"
                    >
                    </v-select>
                    <v-text-field
                      v-else
                      v-model.trim="entity.prodFeeValue"
                      v-on:keyup="$replaceComma"
                      type="numeric"
                      label="Fee Value"
                      suffix="%"
                      :error-messages="errorMessageFeeValue()"
                    >
                    </v-text-field>

                  </v-col>
                </v-row>
              </v-col>
              <v-col class="pr-1" cols="12">
                <v-row no-gutters wrap class="form-block-keystoneV2 h-auto elevation-2" >
                  <v-col cols="12" class="title-form-keystoneV2">
                    SURCOUCHE SETTINGS
                  </v-col>
                  <v-col cols="12">
                    <v-checkbox
                      v-model="entity.prodIsCuicui"
                      label="Is cuicui ?"
                      color="success"
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="entity.prodIsAutoValidation"
                      label="Is auto validation ?"
                      color="success"
                      hide-details
                      :disabled="!isUserAllowedToChangeSurcoucheSettings"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="entity.prodIsFastTracked"
                      label="Is fast tracked ? (AMs Only)"
                      color="success"
                      hide-details
                      :disabled="!isUserAllowedToChangeSurcoucheSettings"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6">
            <v-row no-gutters column>
              <v-col cols="12" class="pl-1">
                <v-row no-gutters wrap class="form-block-keystoneV2 h-auto elevation-2" >
                  <KeystoneMarkupModuleForm
                    :model.sync="currentEntity.markupModule"
                    :dsp="currentEntity && currentEntity.member ? currentEntity.member.dsp : null"
                    :keystone-setting-siege="currentEntity"
                    ref="markupModule"
                  >
                  </KeystoneMarkupModuleForm>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>
      <template v-slot:HISTORY="{ entity }">
        <HistoryLogsComponent
          v-if="currentEntity.id != null && currentEntity.siege.id != null"
          :entityId="currentEntity.id"
          entityType="keystone_settings_siege"
        ></HistoryLogsComponent>
      </template>
    </BaseEditCardEntity>
  </v-dialog>
</template>

<script>
import BaseEditCardEntity from '@/components/KeystoneV2/BaseEditCardEntity'
import { entityFormMixin } from '@/mixins/entityFormMixin'
import { rulesMixin } from '@/mixins/rulesMixin'
import SiegeSettingModel from '@/models/Keystone_v2/SiegeSettingModel'
import DspLoginInfo from '@/components/KeystoneV2/tools/DspLoginInfo'
import SettingsIsActiveInfo from '@/components/KeystoneV2/tools/SettingsIsActiveInfo'
import DspSettingsInfo from '@/components/KeystoneV2/tools/DspSettingsInfo'
import DspSettingsForm from '@/components/KeystoneV2/tools/DspSettingsForm'
import CheckBoxWithInfo from '@/components/Common/CheckBoxWithInfo'
import KeystoneMarkupModuleForm from '@/components/KeystoneV2/Form/KeystoneMarkupModuleForm'
import CollaboratorModel from '@/models/Keystone_v2/CollaboratorModel'
import * as keystoneV2Utils from '../../../../utils/keystoneV2Utils'
import SiegeRuleModel from '@/models/Keystone_v2/SiegeRuleModel'
import ChildEntityAutocomplete from '@/components/KeystoneV2/tools/ChildEntityAutocomplete'
import AdvertiserModel from '@/models/Keystone_v2/AdvertiserModel'
import { getDspSettingsMixin } from '@/mixins/getDspSettingsMixin'
import store from '../../../../store'
import { common } from '../../../../store/modules/common'
import HistoryLogsComponent from '../../TableComponents/Form/FormFragments/HistoryLogs.vue'
import InputFile, { TYPE_CSV } from '@/components/KeystoneV2/tools/InputFile.vue'
import InputTextList from '@/components/KeystoneV2/tools/InputTextList.vue'
import _, { debounce } from 'lodash'
import CopyClipboardComponent from '@/components/Common/CopyClipboardComponent.vue'
import { isNotNullOrUndefined } from '../../../../utils/commonUtils'

if (!store.state.common) store.registerModule('common', common)

export default {
  name: 'SiegeForm',
  components: {
    CopyClipboardComponent,
    InputTextList,
    InputFile,
    ChildEntityAutocomplete,
    KeystoneMarkupModuleForm,
    DspSettingsInfo,
    SettingsIsActiveInfo,
    DspLoginInfo,
    BaseEditCardEntity,
    DspSettingsForm,
    CheckBoxWithInfo,
    HistoryLogsComponent
  },
  mixins: [
    entityFormMixin,
    rulesMixin,
    getDspSettingsMixin
  ],
  data: function () {
    return {
      advertisersOfMember: { 'external_ids': '' },
      loadFullAdvertisers: true,
      importSuccess: true,
      listOfNotMatchIds: [],
      entityType: 'siege',
      editCardItems: [
        { title: 'CRM' },
        { title: 'PROPERTIES' },
        { title: 'HISTORY', disabled: () => { return !this.isEditForm } }
      ],
      loadingSiege: false,
      dspItems: keystoneV2Utils.getDspSelectItems(['youtube']),
      selectedDsp: '',
      processingSiege: false,
      selectedAms: [],
      selectedSales: [],
      loadingCollaborators: false,
      /**
       * @type 'fullMember'|'specificAd'
       */
      specificOrAllAd: 'fullMember',
      loadingSiegeRules: false,
      /**
       * @type {SiegeRuleModel[]}
       */
      currentSiegeRules: [],
      /**
       * @type {AdvertiserModel[]}
       */
      advertiserList: [],
      loadingAdvertisers: false,
      /**
       * @type {AdvertiserModel}
       */
      selectedAdvertiser: null,
      activeTab: 0,
      loadingCheckMember: false,
      checkedMemberExist: false,
      processNewMemberNotSet: false,

      typeFile: TYPE_CSV,
      importButtonValue: 'Import CSV',
      importButtonColor: 'primary',

      /**
       * @type {number|null}
       */
      selectedAgency: null,
      /**
       * @type {number[]}
       */
      agencyList: [],
      errorSiegeRules: [],
      messageSiegeRules: '',
      loadingSiegeRulesChecker: false,
      itemsProdFeeValueAppnexusSegment: [
        0, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5, 16, 16.5, 17, 17.5, 18, 18.5, 19, 19.5, 20
      ],
      historyEntityId: null,
      historyEntityType: '',
      allBut: false,
      feeLabels: {
        [this.$APPNEXUS]: {
          'partner_fee': 'Push fees as partner fees (No Clearing)',
          'segment': 'Push cleared fees (in case of Xandr Partnership)'
        },
        [this.$THETRADEDESK]: {
          'partner_fee': 'Push Fees in an "Additionnal fee card" (No clearing)',
          'cleared_by_dsp': 'Push cleared fees (in case of TTD Partnership)'
        },
        [this.$DBM]: {
          'partner_fee': 'Push Fees as a standard "Media fee" (No clearing)',
          'cleared_by_dsp': 'Push cleared fees (in case of DV360 Partnership)'
        }
      },
      dspWithAutoPushFeesLockedToTrue: [this.$APPNEXUS, this.$THETRADEDESK, this.$DBM],
      feeApplicationModeWithAutoPushFeesLockedToTrue: ['segment', 'cleared_by_dsp']
    }
  },
  methods: {
    checkEntities (elements) {
      for (let element in elements) {
        if (element == null) {
          return false
        }
      }
      return true
    },
    async getAdvertisers (serializedData) {
      const response = await this.$apiCaller.getAdvertisersOfMember(this.currentEntity.member.id, serializedData)
      if (response != null && this.$apiCaller.isResponseError(response, true)) {
        console.warn('Error or call cancelled search', this.entitySearchType)
        if (!this.$apiCaller.isCancelError(response)) {
          this.$store.commit('setErrorMessageWithResponse', response)
        }
      } else {
        return response.data
      }
    },
    addInAdvertiserList (data) {
      if (data) {
        data.map((elem) => {
          let advertiserModel = new AdvertiserModel(elem)
          let index = this.advertiserList.findIndex(x => (x.id === elem.id))
          if (index === -1) {
            this.advertiserList.push(advertiserModel)
          }
        })
      }
    },
    getDiffExternalIds (list, responseObject) {
      const responseList = responseObject.map((x) => x.external_id)
      this.loadFullAdvertisers = (responseList.length === list.length)
      if (list.length > 0) {
        if (responseList.length === 0) {
          this.listOfNotMatchIds = list
        } else {
          this.listOfNotMatchIds = list.filter(x => {
            return !responseList.includes(x)
          })
        }
      }
    },
    errorMessageIntegrity (name) {
      if (!this?.$store?.getters?.getSieges) {
        return []
      }
      let siegeName = this.getEntitiesNameToTest(this.$store.getters.getSieges)
      return this.tempErrorMessageIntegrity(name, siegeName)
    },
    errorMessageName (name) {
      return [...this.errorMessageIntegrity(name), ...this.errorMessageValueCantBeEmpty(name, 'name')]
    },
    closeNewSeat () {
      this.dialog = false
      this.loadFullAdvertisers = true
      this.importSuccess = true
      this.activeTab = 0
    },
    goToMember (siege) {
      this.$store.commit('openEditFormMember', siege.member.id)
    },
    resetValues () {
      this.selectedAms = []
      this.selectedSales = []
      this.specificOrAllAd = 'fullMember'
      this.advertiserList = []
      this.agencyList = []
      this.currentSiegeRules = []
      this.checkedMemberExist = false
      this.loadingSiegeRulesChecker = false
      // clean markup module
      if (this.$refs.markupModule) {
        this.$refs.markupModule.resetMarkupModule()
      }
    },
    getArgsSiegeRulesForApi () {
      if (this.currentEntityDsp === this.$MEDIAMATH) {
        return {
          member_id: this.currentEntity.member.id,
          agency_ids: this.agencyList.map(item => String(item)),
          all_but: this.allBut
        }
      }
      return {
        member_id: this.currentEntity.member.id,
        advertiser_ids: this.advertiserList.map(item => item.id),
        all_but: this.allBut
      }
    },
    /**
     * @param seat {SiegeSettingModel}
     * @return {Promise<void>}
     */
    async saveSeat (seat) {
      this.processingSiege = true
      let options = {
        prod_add_serving_fees: seat.prodAddServingFees,
        prod_fee_value: seat.prodFeeValue,
        prod_inverse_otto_waterfall: seat.prodInverseOttoWaterfall,
        prod_is_auto_validation: seat.prodIsAutoValidation,
        prod_is_cuicui: seat.prodIsCuicui,
        prod_overdelivery_custom: seat.prodOverdeliveryCustom,
        prod_respect_minimum_li_budget: seat.prodRespectMinimumLiBudget,
        prod_underdelivery_custom: seat.prodUnderdeliveryCustom,
        markup_module: seat.markupModule.getObjectForApi(),
        prod_fee_application_mode: seat.prodFeeApplicationMode,
        siege: {
          name: seat.siege.name,
          company_id: seat.siege.company.id
        },
        prod_is_fast_tracked: seat.prodIsFastTracked,
        collaborators: [...this.selectedSales, ...this.selectedAms],
        siege_rules: this.getArgsSiegeRulesForApi()
      }
      let result = null

      if (this.isEditForm) {
        options.id = seat.id
        options.siege.id = seat.siege.id
        result = await this.$store.dispatch('updateSiege', options)
      } else {
        result = await this.$store.dispatch('createSiege', options)
      }

      if (this.$apiCaller.isResponseError(result)) {
        console.warn('Error when process')
        this.$store.commit('setErrorMessageWithResponse', result)
        const barConfig = {
          type: 'error',
          message: 'Error on update or create siege'
        }
        this.$store.commit('setSnackBarConfig', barConfig)
        this.$store.commit('setActiveSnackBar', true)
      } else {
        console.warn('Process success')
        const barConfig = {
          type: 'success',
          message: 'Success !'
        }
        this.$store.commit('setSnackBarConfig', barConfig)
        this.$store.commit('setActiveSnackBar', true)
      }

      this.$emit('refresh')
      this.closeNewSeat()
      this.processingSiege = false
    },
    async getCurrentEntity (siegeId) {
      this.loadingSiege = true
      this.currentEntity = new SiegeSettingModel()

      const response = await this.$apiCaller.getKeystoneSiegeSetting(siegeId)
      if (this.$apiCaller.isResponseError(response)) {
        console.warn('Error when calling siege')
        this.$store.commit('setErrorMessageWithResponse', response)
        this.closeNewSeat()
      } else {
        this.currentEntity = new SiegeSettingModel(response.data[0])
      }

      this.loadingSiege = false
    },
    getChildOfCurrentEntity () {
      console.warn('Not implemented')
    },
    async getDspSettings () {
      if (!(this.currentEntity && this.currentEntity.member && this.currentEntity.member.externalId && this.currentEntity.member.dsp)) {
        return
      }
      const dsp = this.currentEntity.member.dsp
      const memberExternalId = this.currentEntity.member.externalId
      await this.getDspSettingsFunc(dsp, memberExternalId)
    },
    errorMessageFeeApplicationMode () {
      let errors = []

      if (
        this.currentEntityDsp !== this.$BEESWAX &&
        !isNotNullOrUndefined(this.currentEntity.prodFeeApplicationMode)
      ) {
        errors.push('Fee application mode must be set.')
      }

      if (this.currentEntityDsp === this.$THETRADEDESK) {
        const originalValue = this.currentEntity.getOriginal()
        const isFeeApplicationModeChanged = originalValue.prodFeeApplicationMode !== this.currentEntity.prodFeeApplicationMode
        const userAllowedToChangeFeeApplicationMode = ['theophile.dubesset@doubleverify.com']
        const currentUserMail = this.$store.getters.getCurrentUserDvMail
        const isCurrentUserAllowedToChangeFeeApplicationMode = userAllowedToChangeFeeApplicationMode.includes(currentUserMail)
        if (isFeeApplicationModeChanged && this.currentEntity.prodFeeApplicationMode === 'cleared_by_dsp' &&
          !isCurrentUserAllowedToChangeFeeApplicationMode) {
          const allowedUsersString = userAllowedToChangeFeeApplicationMode.map(item => item.split('@')[0]).join(', ')
          errors.push(`In order to activate this feature with TTD, manual steps are to be done by ${allowedUsersString},
          please contact him to proceed with them`)
        }
      }

      return errors
    },
    errorMessageFeeValue () {
      let errors = []

      if (this.currentEntity.prodFeeValue && isNaN(this.currentEntity.prodFeeValue)) {
        errors.push('Fee value must be a numeric')
      }

      if (this.currentEntity.prodAddServingFees && (!this.currentEntity.prodFeeValue && this.currentEntity.prodFeeApplicationMode !== 'segment')) {
        errors.push('Fee value can\'t be empty if you checked the "Automatically push our fees" option')
      }

      return errors
    },
    errorMessageAgencyAdvertiser () {
      let errors = []
      const dsp = this.currentEntity.member.dsp
      if (dsp === this.$MEDIAMATH && this.specificOrAllAd === 'specificAd' && this.agencyList.length === 0) {
        let message = 'You need to choose at least one agency if specific is set'
        errors.push(message)
      }

      if (dsp !== this.$MEDIAMATH && this.specificOrAllAd === 'specificAd' && this.advertiserList.length === 0) {
        let message = 'You need to choose at least one advertiser if specific is set'
        errors.push(message)
      }

      return errors
    },
    async getCurrentCollaborators () {
      const siegeId = this.currentEntity.siege.id
      this.selectedAms = []
      this.selectedSales = []
      this.loadingCollaborators = true

      const response = await this.$apiCaller.getCollaboratorsOfSiege(siegeId)

      if (this.$apiCaller.isResponseError(response)) {
        console.warn('ERROR when calling api collaborators of siege')
        this.$store.commit('setErrorMessageWithResponse', response)
      } else {
        const collList = response.data.map(item => new CollaboratorModel(item))
        this.selectedSales = keystoneV2Utils.collaboratorFilterToId(collList, 'sale')
        this.selectedAms = keystoneV2Utils.collaboratorFilterToId(collList, 'am')
      }

      this.loadingCollaborators = false
    },
    async getCurrentSiegeRules () {
      const siegeId = this.currentEntity.siege.id
      this.loadingSiegeRules = true

      const response = await this.$apiCaller.getSiegeRulesOfSiege(siegeId)
      const filterType = response.data && response.data[0] ? response.data[0].all_but : false

      if (this.$apiCaller.isResponseError(response)) {
        console.warn('ERROR when calling api siege rules of siege')
        this.$store.commit('setErrorMessageWithResponse', response)
      } else {
        this.currentSiegeRules = response.data.map(item => new SiegeRuleModel(item))

        if (this.currentEntityDsp === this.$MEDIAMATH) {
          this.agencyList = this.currentSiegeRules.filter(item => item.agencyId).map(item => item.agencyId)
          this.specificOrAllAd = this.agencyList.length ? 'specificAd' : 'fullMember'
          this.allBut = filterType
        } else {
          this.advertiserList = this.currentSiegeRules.filter(item => item.advertiser && item.advertiser.id && item.advertiser.external_id).map(item => new AdvertiserModel(item.advertiser))
          this.specificOrAllAd = this.advertiserList.length ? 'specificAd' : 'fullMember'
          this.allBut = filterType
        }
      }

      this.loadingSiegeRules = false
    },
    addAdvertiser () {
      if (this.selectedAdvertiser && this.selectedAdvertiser.id) {
        this.advertiserList.push(this.selectedAdvertiser)
      }
      this.selectedAdvertiser = null
    },
    addAgency () {
      this.agencyList.push(this.selectedAgency)
      this.selectedAgency = null
    },
    /**
     * @param advertiser {AdvertiserModel}
     */
    removeAdvertiser (advertiser) {
      const index = this.advertiserList.indexOf(advertiser)
      if (index > -1) {
        this.advertiserList.splice(index, 1)
      }
    },
    removeAgency (agencyId) {
      const index = this.agencyList.indexOf(agencyId)
      if (index > -1) {
        this.agencyList.splice(index, 1)
      }
    },
    /**
     * @param item {AdvertiserModel}
     */
    itemDisabledAdvertiser (item) {
      return this.advertiserList.map(item => item.id).indexOf(item.id) !== -1
    },
    /**
     * @param item {{text: number, value: number}}
     */
    itemDisabledAgency (item) {
      return this.agencyList.indexOf(item.value) !== -1
    },
    async checkMember () {
      if (!this.currentEntity.member || !this.currentEntity.member.externalId || !this.currentEntity.member.dsp) {
        return
      }

      this.loadingCheckMember = true
      this.checkedMemberExist = false
      const data = {
        external_id: this.currentEntity.member.externalId,
        dsp: this.currentEntity.member.dsp
      }

      const response = await this.$apiCaller.getMembers(data)

      if (this.$apiCaller.isResponseError(response, true)) {
        console.warn('ERROR when calling api members')
        if (!this.$apiCaller.isCancelError(response)) {
          this.$store.commit('setErrorMessageWithResponse', response)
        }
      } else {
        if (response.data.length) {
          const member = response.data[0]
          this.currentEntity.member.id = member.id
          this.currentEntity.member.isSet = member.is_set
          this.checkedMemberExist = true
        }
      }

      this.loadingCheckMember = false
    },
    async createNewMemberNotSet () {
      if (!this.currentEntity || !this.currentEntity.member || !this.currentEntity.member.externalId || !this.currentEntity.member.dsp) {
        return
      }

      this.processNewMemberNotSet = true
      const response = await this.$apiCaller.postMembers(
        'NOT SET',
        this.currentEntity.member.dsp,
        this.currentEntity.member.externalId,
        false
      )

      if (this.$apiCaller.isResponseError(response)) {
        console.warn('ERROR when creating not set member')
        this.$store.commit('setErrorMessageWithResponse', response)
      } else {
        console.warn('New member successfully created')
        this.checkedMemberExist = true
        this.currentEntity.member.id = response.data.id
        this.currentEntity.member.isSet = response.data.is_set
      }

      this.processNewMemberNotSet = false
    },
    async checkSiegeRules () {
      if (!this.currentEntityDsp) {
        console.warn('No dsp selected, no siege rules to check')
        return
      }

      if (!this.currentEntity) {
        console.warn('No siege selected, no siege rules to check')
        return
      }
      let response = null
      this.loadingSiegeRulesChecker = true
      this.errorSiegeRules = []

      const siegeId = this.currentEntity && this.currentEntity.siege && this.currentEntity.siege.id
        ? this.currentEntity.siege.id
        : null

      if (this.specificOrAllAd === 'fullMember') {
        response = await this.$apiCaller.getSiegeRuleChecker(
          this.currentEntityDsp,
          this.currentEntity.member.id,
          siegeId,
          null,
          null,
          this.allBut
        )
      } else {
        if (this.currentEntityDsp === this.$MEDIAMATH) {
          response = await this.$apiCaller.getSiegeRuleChecker(
            this.currentEntityDsp,
            this.currentEntity.member.id,
            siegeId,
            null,
            this.agencyList.join(','),
            this.allBut
          )
        } else {
          response = await this.$apiCaller.getSiegeRuleChecker(
            this.currentEntityDsp,
            this.currentEntity.member.id,
            siegeId,
            this.advertiserList.map(elem => elem.id).join(','),
            null,
            this.allBut
          )
        }
      }

      if (response !== null && this.$apiCaller.isResponseError(response)) {
        if (response.status === 403) {
          this.$store.commit('setErrorMessageWithResponse', response)
          this.errorSiegeRules.push('You don\'t have the right for the endpoint check siegerules. Ask a administrator for access this endpoint.')
        } else if ([500, 503].includes(response.status)) {
          this.$store.commit('setErrorMessageWithResponse', response)
        }
        this.errorSiegeRules.push(response.response.data.errors[0])
      }

      this.loadingSiegeRulesChecker = false
    },
    // Different labels for Xandr, TTD & DBM
    getFeeApplicationModeLabel (dsp, value) {
      if ([this.$APPNEXUS, this.$THETRADEDESK, this.$DBM].includes(dsp) && ['segment', 'partner_fee', 'cleared_by_dsp'].includes(value)) {
        return this.feeLabels[dsp][value]
      }
      return '/!\\ Error, please contact a member of the dev team. /!\\'
    },
    getCollaboratorNameFromMail (collaborator) {
      return this.$commonUtils.getCollaboratorNameFromMail(collaborator)
    }
  },
  computed: {
    amItems () {
      return keystoneV2Utils.collaboratorFilterToSelectItem(this.$store.getters.getCollaborators, 'am')
    },
    saleItems () {
      return keystoneV2Utils.collaboratorFilterToSelectItem(this.$store.getters.getCollaborators, 'sale')
    },
    dspHasDspLogin () {
      return [this.$APPNEXUS, this.$BEESWAX, this.$MEDIAMATH, this.$THETRADEDESK].indexOf(this.currentDsp) !== -1
    },
    dspLogin () {
      return this.currentEntity.dspLoginInfo && this.currentEntity.dspLoginInfo.username
        ? this.currentEntity.dspLoginInfo.username
        : undefined
    },
    currentDsp () {
      return this.currentEntity && this.currentEntity.member ? this.currentEntity.member.dsp : null
    },
    labelRespectMinimumLiBudget () {
      let lineLabel = ''

      if ([this.$APPNEXUS, this.$BEESWAX, this.$DBM].indexOf(this.currentDsp) !== -1) {
        lineLabel = 'LI'
      } else if ([this.$MEDIAMATH].indexOf(this.currentDsp) !== -1) {
        lineLabel = 'Strategy'
      } else if ([this.$THETRADEDESK].indexOf(this.currentDsp) !== -1) {
        lineLabel = 'Adgroup'
      } else {
        lineLabel = 'LI'
      }

      return `Respect Minimum ${lineLabel} Budget`
    },
    advertiserListJoin () {
      return this.advertiserList.map(item => item.name).join(',')
    },
    memberIsSet () {
      return this.currentEntity.member && this.currentEntity.member && this.currentEntity.member.id
    },
    isUserAllowedToChangeSurcoucheSettings () {
      return this.$store.getters.isUserDebugger || this.$store.getters.isUserAm
    },
    noComplexSiegeRulePossible () {
      return this.currentEntity && this.currentEntity.member && !this.currentEntity.member.isSet && this.checkedMemberExist
    },
    currentEntityDsp () {
      if (!this.currentEntity || !this.currentEntity.member || !this.currentEntity.member.dsp) {
        return ''
      }
      return this.currentEntity.member.dsp
    },
    labelSpecificAd () {
      return `A list of specific ${this.currentEntityDsp === this.$MEDIAMATH ? 'agency' : 'advertiser'}`
    },
    hasSiegeRuleErrors () {
      return Boolean(this.errorSiegeRules.length)
    },
    feeValueAppnexusSegment () {
      let res = [...this.itemsProdFeeValueAppnexusSegment]
      res.sort((a, b) => a - b)
      return res
    },
    prodAddServingFeesIsReadonly () {
      return (
        this.dspWithAutoPushFeesLockedToTrue.includes(this.currentEntityDsp) &&
        this.feeApplicationModeWithAutoPushFeesLockedToTrue.includes(this.currentEntity.prodFeeApplicationMode)
      )
    }
  },
  watch: {
    'currentEntity.siege.id': {
      deep: true,
      immediate: true,
      handler: function (currentEntityId) {
        this.resetValues()
        if (currentEntityId) {
          this.getCurrentCollaborators()
          this.getCurrentSiegeRules()
        }
      }
    },
    'currentEntity.member.externalId': {
      deep: true,
      immediate: true,
      handler: _.debounce(function (externalId) {
        if (externalId) {
          this.getDspSettings()
          this.checkMember()
        } else {
          console.warn('No external id')
          this.dspSettings = null
          this.agencyList = []
          this.advertiserList = []
        }
      }, 600)
    },
    specificOrAllAd: {
      handler: function (value) {
        if (value === 'fullMember') {
          this.advertiserList = []
          this.agencyList = []
        }
      }
    },
    checkedMemberExist: {
      immediate: true,
      handler: function (value) {
        if (value) {
          this.checkSiegeRules()
        } else {
          this.errorSiegeRules = []
        }
      }
    },
    allBut: {
      immediate: true,
      deep: true,
      handler: function (value) {
        if (this.checkedMemberExist) {
          this.checkSiegeRules()
        }
      }
    },
    advertiserList: {
      immediate: true,
      deep: true,
      handler: function (value) {
        if (this.checkedMemberExist && this.currentEntityDsp !== this.$MEDIAMATH) {
          if (!(this.specificOrAllAd === 'specificAd' && this.advertiserList.length === 0)) {
            this.checkSiegeRules()
          }
        }
      }
    },
    advertisersOfMember: {
      immediate: false,
      deep: true,
      handler: async function (value) {
        if (value) {
          const response = await this.getAdvertisers(value)
          const listExternalIds = value.external_ids.trim().split(',')
          this.getDiffExternalIds(listExternalIds, response.data)
          this.addInAdvertiserList(response.data)
        }
      }
    },
    agencyList: {
      immediate: true,
      deep: true,
      handler: function (value) {
        if (this.checkedMemberExist && this.currentEntityDsp === this.$MEDIAMATH) {
          if (!(this.specificOrAllAd === 'specificAd' && this.agencyList.length === 0)) {
            this.checkSiegeRules()
          }
        }
      }
    },
    'currentEntity.prodFeeApplicationMode': {
      handler: function (value) {
        if (
          this.dspWithAutoPushFeesLockedToTrue.includes(this.currentEntityDsp) &&
          this.feeApplicationModeWithAutoPushFeesLockedToTrue.includes(value) &&
          this.feeApplicationModeWithAutoPushFeesLockedToTrue.includes(this.currentEntity.prodFeeApplicationMode)
        ) {
          this.currentEntity.prodAddServingFees = true
        }
      }
    }
  }
}
</script>

<style scoped>
.chipsArea {
  overflow: auto;
  max-height: 200px;
  margin-top: 10px;
}
.info-add {
  font-style: italic;
  font-size: 11px;
}
</style>

<style>
.label-btn {
  line-height: 37px;
  font-weight: 300;
  color: rgba(0,0,0,0.54);
}

.value-data {
  line-height: 37px;
}

.h-auto {
  height: auto;
}

#siege-properties-tab .v-input {
  margin-top: 3px;
}
</style>
